.Header {
  background-color: #222;
  padding: 10px 0;
}

.Header nav ul {
  list-style: none;
  padding: 0;
}

.Header nav ul li {
  display: inline;
  margin-right: 20px;
}

.Header nav ul li a {
  color: white;
  text-decoration: none;
}

/* Add additional header styles here */