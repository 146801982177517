.contact-form {
    padding: 20px;
    border-radius: 10px;
  }
  
  .contact-form label {
    color: black;
  }
  
  .contact-form .heading {
    font-size: 24px;
    color: black;
    ;
    margin-bottom: 12px;
    font-weight: bold;
    display: block;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    margin-bottom: 10px;
  }
  
  textarea {
    resize: none;
    height: 80px;
    width: 200px;
  }
  
  .contact-form input, .contact-form textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .contact-form input:focus, .contact-form textarea:focus {
    outline: none;
    box-shadow: 0 0 5px #DC8E47;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  
  .contact-form button[type="submit"] {
    background-color: #DC8E47;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .contact-form button[type="submit"]:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
  